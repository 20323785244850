import {NgModule} from '@angular/core';
import {AccountService} from './account.service';
import {AuthService} from './auth.service';
import {AuthGuard} from './auth-guard.service';
import {AuthServerProvider} from './auth-jwt.service';
import {Principal} from './principal.service';
import {StateStorageService} from './state-storage.service';
import {CommonModule} from '@angular/common';
import {NgxWebstorageModule} from 'ngx-webstorage';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    NgxWebstorageModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    Principal,
    AuthService,
    AccountService,
    AuthServerProvider,
    StateStorageService,
  ]
})
export class AuthModule { }
