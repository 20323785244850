import {AfterViewInit, Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';

@Component({
  selector: 'app-date-custom',
  templateUrl: './date-custom.component.html',
  styleUrls: ['./date-custom.component.scss'],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateCustomComponent),
    multi: true}
    ]
})
export class DateCustomComponent implements ControlValueAccessor, OnInit, AfterViewInit  {

  @ViewChild('input') input: NgModel;
  @Input('name')  _name: string;
  @Input('label')  _label: string;
  @Input('placeholder')  private _placeholder: string;
  @Input('disabled') private _disabled = false;
  @Input('readonly') private _readonly = false;
  @Input('control') private control: AbstractControl;
  @Input('value') private _value: string;




  private propagateChange: Function;
  private onTouched: Function;
  private _isError: boolean;
  private _isRequired: boolean;
  private _isTouch = false;
  private _isChanged = false;
  private _messageError: string;
  constructor() {}

  ngAfterViewInit(): void {
    if (!this.name) {
      throw new Error('Inserire un nome alla Input [name]');
    }

    this.registerOnChange(() => {
      this._isError = false;
      this._messageError = '';
      this._isTouch = true;
      if(this._value !== this.control.value) {
        this._isChanged = true;
      }
      if (this.control.invalid) {
        this._isError = true;
        if (this.isRequired && !this.control.errors.err) {
          this._messageError = 'Campo Obbligatorio';
        } else {
          this._messageError = this.control.errors.err;
        }
      } else {
        this._isError = false;
      }
    });
  }

  hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({}as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
  }

  ngOnInit(): void {
    this._isRequired = this.hasRequiredField(this.control);
    this.value = this._value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.control.setValue(obj);
  }


  change( $event ) {
    this.propagateChange($event.target);
  }
  // <editor-fold desc="SET GETTER">
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get label(): string {
    return this._label;
  }
  set label(value: string) {
    this._label = value;
  }
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
  }
  get value(): string {
    return this.control.value;
  }
  set value(value: string) {
    this.control.setValue(value);
    this._value = value;
  }
  get isError(): boolean {
    return this._isError;
  }
  get isRequired(): boolean {
    return this._isRequired;
  }
  get isTouch(): boolean {
    return this._isTouch;
  }
  get isChanged(): boolean {
    return this._isChanged;
  }
  get messageError(): string {
    return this._messageError;
  }
  get readonly(): boolean {
    return this._readonly;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  // </editor-fold>

}
