import {CommonModule} from '@angular/common';
import {AlertService} from './alert.service';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {AlertErrorComponent} from './alert-error.component';
import {NgJhipsterModule} from 'ng-jhipster';

@NgModule({
  declarations: [
    AlertErrorComponent
  ],
  imports: [
    CommonModule,
    NgJhipsterModule.forRoot({
      // set below to true to make alerts look like toast
      alertAsToast: true,
    }),
  ],
  providers: [
    AlertService
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  exports: [
    AlertErrorComponent
  ]
})
export class AlertModule { }
