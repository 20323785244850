import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {LoadingService} from './loading.service';
import {Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import {Component, Directive} from '@angular/core';

// import {LoadingService} from './loading.service';

export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private loadingService: LoadingService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || (/^http/.test(request.url)
      && !(environment.apiUrl && environment.documentUrl
        && (request.url.startsWith(environment.apiUrl) || request.url.startsWith(environment.documentUrl)
          )))) {
      return next.handle(request);
    }

    const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');

    if (!!token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    if (!request.url.includes('autocomplete')) {
      this.loadingService.incrementCreation();
    }
    return next.handle(request).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event.type) {
          if (!request.url.includes('autocomplete')) {
            this.loadingService.incrementResolution();
          }
        }
      }, (err: any) => {
        if (request) {
          if (!request.url.includes('autocomplete')) {
            this.loadingService.incrementResolution();
          }
        }
      }));
  }

}
