import {Injectable} from '@angular/core';

@Injectable()
export class LoadingService {

  private countCreation = 0;
  private countResolution = 0;

  constructor() {}

  incrementCreation() {
    this.countCreation++;
  }
  incrementResolution() {
    this.countResolution++;
  }

  isLoading() {
    return this.countResolution < this.countCreation;
  }
}
