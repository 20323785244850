import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class AccountService {
  constructor(private http: HttpClient) {
  }

  get(): Observable<HttpResponse<Account>> {
    return this.http.get<Account>(environment.apiUrl + 'auth/me', {observe: 'response'});
  }

  save(account: any): Observable<HttpResponse<any>> {
    return this.http.post(environment.apiUrl + 'auth/me', account, {observe: 'response'});
  }

  editPwd(idUser: number, password: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'auth/change_password/' + idUser, {password: password});
  }

  acceptPrivacy(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'auth/accept_privacy');
  }

  searchAccess(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + 'auth/access_list');
  }


}
