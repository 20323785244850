import {Injectable} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {ModalGenericComponent} from './modal-generic.component';

@Injectable()
export class ModalGenericService {
  bsModalRef: BsModalRef;
  modalIsOpen = false;

  constructor(private modalService: BsModalService) {
    this.setModalListner();
  }

  setModalListner() {
    this.modalService.onHide.subscribe(() => {
      this.modalIsOpen = false;
    });
    this.modalService.onShow.subscribe(() => {
      this.modalIsOpen = true;
    });
  }

  isOpen() {
    return this.modalIsOpen;
  }

  open(options?: any): BsModalRef {
    this.bsModalRef = this.modalService.show(ModalGenericComponent, {class: 'modal-md'});
    this.bsModalRef.content.options = options;
    this.bsModalRef.content.closeBtnName = 'Close';
    return this.bsModalRef;
  }

  openNavigate(options?: any): BsModalRef {
    this.bsModalRef = this.modalService.show(ModalGenericComponent, {class: 'modal-md', ignoreBackdropClick: true});
    options.isNavigate = true;
    this.bsModalRef.content.options = options;
    this.bsModalRef.content.closeBtnName = 'Close';
    return this.bsModalRef;
  }
}
