import {JhiEventManager} from 'ng-jhipster';
import {HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injector} from '@angular/core';

export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private eventManager: JhiEventManager,
              private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return next.handle(request).do((event: HttpEvent<any>) => {}, (err: any) => {
    //   if (err instanceof HttpErrorResponse) {
    //     if (!(err.status === 401 && (err.message === '' || (err.url && err.url.indexOf('/users/me') >= 0)))) {
    //       if (this.eventManager !== undefined) {
    //         this.eventManager.broadcast({name: 'jhipsterApp.httpError', content: err});
    //
    //         const router: Router = this.injector.get(Router);
    //         switch (err.status) {
    //           // connection refused, server not reachable
    //           case 0:
    //             router.navigate(['/500']);
    //             break;
    //
    //           case 400:
    //             // router.navigate(['/']);
    //             break;
    //
    //           case 404:
    //             router.navigate(['/404']);
    //             break;
    //
    //           default:
    //             router.navigate(['/500']);
    //         }
    //
    //       }
    //     }
    //   }
    // });

    return next.handle(request).pipe(catchError(
      (err: HttpErrorResponse) => {
        if (!(err.status === 401 && (err.message === ''
          || (err.url && err.url.indexOf('/users/me') >= 0) || (err.url && err.url.indexOf('/auth/login') >= 0)))) {
          if (this.eventManager !== undefined) {
            this.eventManager.broadcast({name: 'jhipsterApp.httpError', content: err});
          }
          const router: Router = this.injector.get(Router);
          switch (err.status) {
            // connection refused, server not reachable
            case 0:
              router.navigate(['/0']);
              break;

            case 400:
              // router.navigate(['/']);
              break;

            case 404:
              router.navigate(['/404']);
              break;
            default:
              router.navigate(['/500']);
          }
        }
        return throwError(err);
      }
    ));
  }
}
