import {NgModule} from '@angular/core';
import {FullLayoutComponent} from './full/full-layout.component';
import {ContentLayoutComponent} from './content/content-layout.component';
import {NavbarComponent} from './navbar/navbar.component';
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {BsModalService} from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    SharedModule.forRoot(),

  ],
  providers: [
    BsModalService,
  ]
})
export class LayoutModule { }
