import {RouteInfo} from './sidebar.metadata';
import {STRUCTURE, SUPER_ADMIN} from '../../app.constants';

export const ROUTES: RouteInfo[] = [
  {
    path: '/welcome',
    title: 'Dashboard',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [SUPER_ADMIN,STRUCTURE]
  },

];
