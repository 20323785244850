<div class="form-group">
  <label [attr.for]="name" >{{label}}<i *ngIf="isRequired">*</i></label>
  <div class="input-group">
    <input class="form-control" placeholder="yyyy-mm-dd"
           name="dp"  ngbDatepicker #d="ngbDatepicker"
           [attr.id]="name"
           [attr.name]="name"
           [attr.placeholder]="readonly?'':placeholder"
           type="text" #input
           (blur)="change($event)"
           (keyup)="writeValue($event.target.value)"
           (change)="writeValue($event.target.value)"
           (dateSelect)="writeValue($event)"
           [attr.value]="value"
           [class.ng-changed] = "isChanged"
           [class.ng-readOnly] = "readonly"
           [class.ng-disabled] = "disabled"
           [attr.readOnly] = "readonly? '' : null"
           [attr.disabled]="disabled ? '' : null"
           [class.ng-touched]="isTouch"
           [class.ng-invalid]="isError"
           #input
    >
    <div class="input-group-append" *ngIf="!disabled">
      <button class="btn btn-outline-danger btn-calendar" (click)="d.toggle()" type="button">
      </button>
    </div>
  </div>
</div>
