import {ModuleWithProviders, NgModule} from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';

import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from 'app/shared/pipes/pipe.module';

//COMPONENTS

import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {ModalGenericService} from './modal-generic/modal-generic.service';
import {ModalGenericComponent} from './modal-generic/modal-generic.component';
import {DateCustomComponent} from './date-custom/date-custom.component';
import {CommonModule} from '@angular/common';
import {ToggleFullscreenDirective} from './directives/toggle-fullscreen.directive';
import {RouterModule} from '@angular/router';
import {NgSelectComponent, NgSelectModule} from '@ng-select/ng-select';
import {SweetAlertsComponent} from './sweet-alerts/sweet-alerts.component';


@NgModule({
    exports: [
        CommonModule,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        NgbModule,
        DateCustomComponent,
        NgSelectModule,
        SweetAlertsComponent
    ],
    providers: [
      BsModalService,
      ModalGenericService,
    ],
    entryComponents: [
      ModalGenericComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule ,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        ModalModule.forRoot(),
    ],
    declarations: [
      SweetAlertsComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        ModalGenericComponent,
        DateCustomComponent

    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [ModalGenericService]
    };
  }
}
