import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AlertService {

  alerts = [];

  constructor(private toastr: ToastrService) {}

  addAlert(alert) {
    if (alert.type === 'warning') {
      this.toastr.warning(alert.msg, 'Warning');
    } else {
      this.toastr.success(alert.msg, 'Success');

    }
    //this.alerts.push(alert);
  }

  getAlerts() {
    return this.alerts;
  }
}
